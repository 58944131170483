import Vue from "vue"
import elLocale from "element-ui/lib/locale"
import {
  Pagination,
  Dialog,
  Input,
  Radio,
  RadioGroup,
  Popover,
  Tabs,
  TabPane,
  Collapse,
  CollapseItem,
  Notification,
  Autocomplete,
  DatePicker,
  TimePicker,
  Tooltip,
} from "element-ui"

Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Input)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Popover)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Autocomplete)
Vue.use(DatePicker)
Vue.use(TimePicker)
Vue.use(Tooltip)

Vue.prototype.$notify = Notification

const getRuLocale = async () =>
  (await import("element-ui/lib/locale/lang/ru-RU")).default
const getEnLocale = async () =>
  (await import("element-ui/lib/locale/lang/en")).default
const getTrLocale = async () =>
  (await import("element-ui/lib/locale/lang/tr-TR")).default

const setLocale = async (locale) => {
  let messages

  if (locale === "ru") messages = await getRuLocale()
  else if (locale === "tr") messages = await getTrLocale()
  else messages = await getEnLocale()

  elLocale.use(messages)
}

export default ({ app }) => {
  setLocale(app.i18n.locale)
  app.i18n.onLanguageSwitched = (_oldLocale, newLocale) => setLocale(newLocale)
}
