
import { defineComponent } from "vue"
import type { LocaleObject } from "@nuxtjs/i18n"

export default defineComponent({
  data () {
    return {
      locale: this.$i18n.locale,
    }
  },

  computed: {
    locales (): string[] {
      const locales = this.$i18n.locales as LocaleObject[]
      return locales.map(l => l.code).sort((a, b) => a.localeCompare(b))
    },
  },

  mounted () {
    this.$watch("locale", (locale) => {
      window.location.href = this.switchLocalePath(locale)
    })
  },
})
